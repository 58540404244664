<!-- *************************** 加速页下半部分 ****************************** -->
<template>
  <div>
    <div class="method">
      <div class="container">
        <div class="content">
          <h4 class="title">使用Transgle加速方法</h4>
          <div class="method_top">
            <p>在海外需要回国访问中国音视频和网站，就用回国加速领先品牌Transgle。可以访问和加速音视频、国内网站，满足你回国看视频，百万用户的选择。</p>
            <div class="method_box">
              <div class="box">
                <img src="../assets/1.png" alt />

                <div class="way" v-if="type==1">
                  <h4>下载设备</h4>
                  <p>在你的设备下载并安装Transgle 加速器</p>
                </div>
                <div class="way" v-else>
                  <h4>免费注册</h4>
                  <p>在本网站免费注册成为会员</p>
                </div>
              </div>
              <div class="box">
                <img src="../assets/2.png" alt />
                <div class="way" v-if="type==1">
                  <h4>免费注册</h4>
                  <p>使用手机号注册、登录进入应用</p>
                </div>
                <div class="way" v-else>
                  <h4>下单购买</h4>
                  <p>填写收货地址并点击购买家庭套餐</p>
                </div>
              </div>
              <div class="box">
                <img src="../assets/3.png" alt />
                <div class="way" v-if="type==1">
                  <h4>一键开启加速</h4>
                  <p>点击加速按钮，重启需要加速的应用即可</p>
                </div>
                <div class="way" v-else>
                  <h4>即插即用</h4>
                  <p>收货以后插上此盒即可开始享受无界网络</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    type:Number //1:下载 2:购买
  },
  mounted(){
    console.log(this.type)
  }
};
</script>
<style lang="scss">
.method {
  padding: 32px;
  background: #fff;
  .title {
    font-size: 48px;
    font-weight: 400;
    color: #202020;
  }
  .method_top {
    .title {
      font-size: 48px;
      font-weight: 400;
      color: #202020;
    }
    & > p {
      font-size: 16px;
      font-weight: 400;
      color: #4a4a4a;
      margin: 32px 0;
      text-align: center;
    }
    .method_box {
      @include flex_around;
      width: 900px;
      margin: 0 auto;
      height: 174px;
      background: #edf9ff;
      border-radius: 126px;
      .box {
        display: flex;
        align-items: center;
        img {
          width: 56px;
          height: 56px;
        }
        .way {
          text-align: left;
          width: 156px;
          h4 {
            font-size: 20px;
            font-weight: 400;
            color: #202020;
            margin-bottom: 11px;
          }
          p {
            font-size: 12px;
            font-weight: 400;
            color: #7e7e7e;
            line-height: 17px;
          }
        }
      }
    }
  }
  .method_bottom {
    .method_b_box_content {
      margin-top: 45px;
      @include flex_between;
      .intro {
        position: relative;
        h4 {
          font-size: 18px;
        }
        border: 2px solid #517cfc;
        .intro_logo {
          display: inline-block;
          padding: 3px 20px;
          color: #fff;
          font-size: 18px;
          position: absolute;
          right: 0;
          top: 0;
          border-bottom-left-radius: 5px;
          background: #517cfc;
        }
      }
      .method_b_box {
        box-sizing: border-box;
        width: 220px;
        padding: 41px 20px 25px 20px;
        background: #edf9ff;
        border-radius: 4px;
        h4 {
          font-size: 16px;
          font-weight: 500;
          color: #202020;
        }
        p {
          margin: 15px;
          font-size: 36px;
          font-weight: bold;
          color: #517cfc;
          span {
            padding-left: 6px;
            font-size: 16px;
            font-weight: bold;
            color: #bcc5d4;
          }
        }
        button {
          width: 98px;
          height: 38px;
          border-radius: 5px;
          border: 1px solid #517cfc;
          font-size: 14px;
          font-weight: 500;
          color: #517cfc;
          background: #edf9ff;
        }
      }
    }
  }
}
</style>