<!-- *************************** 获得硬件 ****************************** -->
<template>
  <div class="andriod_dowload">
    <div class="container down_load_content">
      <img style='margin-top: -30px;' src="../assets/meal_box.png" alt />
      <div class="download_text">
        <h1>购买家庭加速盒</h1>
        <p>无需任何安装软件，加速盒子领回家，接上网线就能让全家享受回家加速服务， 性价比超高。</p>
        <ul>
          <li>
            <img src="../assets/mark.png" alt />
            <span>即插即用，支持所有模式和场景。</span>
          </li>
          <li>
            <img src="../assets/mark.png" alt />
            <span>在同一地点内的家庭所有成员同时使用。</span>
          </li>
          <li>
            <img src="../assets/mark.png" alt />
            <span>支持视频、游戏等应用，稳定高效简单。</span>
          </li>
        </ul>
        <div class="tool_b_box_content" v-if="toolList.length > 0">
          <div v-for="(item) in toolList" :key="item.version">
            <router-link to="/recharge">
              <button class="android_btn">
                <span>立即购买</span>
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <AcclerateWays :type="2"/>
  </div>
</template>
<script>
import { getToolListApi } from "api/Android";
import AcclerateWays from "@/views/AcclerateWays.vue";
export default {
  components: {
    AcclerateWays
  },
  data() {
    return {
      toolList: []
    };
  },
  mounted() {
    getToolListApi().then(res => {
      if (res.code === 0) {
        this.toolList = res.data;
      }
    });
  },
  methods: {
    openDownloadUrl(url) {
      window.open(url, "_blank");
    }
  }
};
</script>
<style lang="scss">
.andriod_dowload {
  .down_load_content {
    @include flex-between;
    margin-top: 47px;
    margin-bottom: 35px;
    & > img {
      height: 425px;
    }
    .download_text {
      width: 634px;
      text-align: left;
      h1 {
        font-size: 48px;
        font-weight: 400;
        color: #202020;
      }
      p {
        font-size: 18px;
        font-weight: 400;
        color: #afadb8;
        margin-top: 18px;
        margin-bottom: 34px;
        line-height: 25px;
      }
      ul {
        li {
          vertical-align: middle;
          font-size: 18px;
          font-weight: 400;
          color: #202020;
          margin-bottom: 30px;

          img {
            width: 16px;
            vertical-align: middle;
            margin-right: 30px;
          }
        }
      }
      .android_btn {
        width: 238px;
        height: 54px;
        background: #517cfc;
        border-radius: 5px;
        font-size: 16px;
        font-weight: 500;
        color: #fff;
        img {
          width: 28px;
          height: 28px;
          vertical-align: middle;
          margin-right: 18px;
        }
      }
      .version {
        margin-top: 24px;
        button {
          width: 104px;
          height: 26px;
          background: rgba(81, 124, 252, 0.2);
          border-radius: 13px;
          font-size: 12px;
          font-weight: 400;
          color: #517cfc;
        }
      }
    }
  }
}
</style>